import {NgModule} from '@angular/core';
import {ReplaceSpacesInStringPipe} from './replace-spaces-in-string.pipe';

@NgModule({
	declarations: [ReplaceSpacesInStringPipe],
	exports: [ReplaceSpacesInStringPipe]
})

export class ReplaceSpacesInStringModule {
}
